import WebFont from 'webfontloader';

let loadedFonts = new Set();

export function loadFont(name) {
    if (loadedFonts.has(name)) return;

    WebFont.load({
        google: {
            families: [name]
        },
        active: () => {
            loadedFonts.add(name)
        }
    })
}
