export function priceCalculator(node, options) {
    if (!(node instanceof Node)) return;

    const optionsValues = {
        base: node.dataset.baseValue
    };

    const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    })
    calc();

    console.log(options)

    options.forEach((el, i) => {
        if (!el instanceof Node) return;
        const value = el.dataset.value;
        const type = el.dataset.type;
        switch (type) {
            case 'checkbox':
                const checkbox = el.querySelector(el.dataset.checkbox);
                new MutationObserver(evt => {
                    if(evt[0].attributeName === 'checked') {
                        optionsValues[i] = checkbox.checked ? value : 0;
                        calc();
                    }
                }).observe(checkbox, {attributes: true});
                break;
            case 'dropdown':
                el.addEventListener('change', evt => {
                    const opt = evt.target.options[evt.target.selectedIndex];
                    optionsValues[i] = opt.dataset.value;
                    calc();
                })
                break;
        }
    })

    function calc() {
        const calculatedPrice = (Object.values(optionsValues)
            .reduce((carry, current) => parseFloat(carry) + parseFloat(current), 0))
        node.innerText = formatter.format(calculatedPrice);
    }

}
