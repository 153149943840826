import {colorSelector} from "./color-selector";

export function barelConfigurator(node) {
    if (!(node instanceof Node)) return;

    let colorSelectorEl = document.querySelector(node.dataset.colorSelector);
    let img = document.querySelector(node.dataset.imageTarget);
    let surfaceTypeInput = document.querySelector(node.dataset.surfaceSelector)

    let selectedEl;
    let isShiny = surfaceTypeInput.value === 'shiny';
    surfaceTypeInput.addEventListener('change', evt => {
        isShiny = evt.target.value === 'shiny';
        reloadImg(selectedEl, isShiny);
    })

    colorSelector(colorSelectorEl, el => {
        selectedEl = el;
        reloadImg(el, isShiny)
    }, true)

    function reloadImg(colorEl, shiny)
    {
        img.src = colorEl.dataset[shiny ? 'imgUrlShiny' : 'imgUrlMat'];
    }

}
