import {tabs} from "./tabs";
import {loadFont} from "./font-loader";
import {colorSelector} from "./color-selector";
import interact from "interactjs";
import html2canvas from 'html2canvas';

export function contentConfigurator(imgSource, config) {
    const {
        imageContainer,
        text,
        image,
        form
    } = config;

    const {
        input: textInput,
        font: fontSelector,
        size: textSizeInput,
        color: colorSelectorEl
    } = text;

    const {
        input: imageField,
        zone: dropZone,
        ratio: ratioCheckbox
    } = image;

    if (!(imageContainer instanceof Node)) return;

    let customTxt, customImg, limits, resizeRestrict, dragRestrict, currentColor;

    //Custom Text
    textInput.addEventListener('keydown', (evt) => {
        if (evt.target.value.length >= 120
            && evt.key !== 'Backspace'
            && !evt.ctrlKey
            && !evt.altKey
        ) {
            evt.preventDefault();
            return;
        }
    })
    textInput.addEventListener('input', (evt) => {
        if (!(customTxt instanceof Node)) return;
        customTxt.innerText = evt.target.value;
    })
    fontSelector.addEventListener('change', evt => {
        if (!(customTxt instanceof Node)) return;
        const selectedFont = evt.target.options[evt.target.options.selectedIndex].dataset;
        loadFont(`${selectedFont.font}:${selectedFont.weight}`);
        customTxt.style.fontFamily = `${selectedFont.font}, ${selectedFont.alternative}`
        customTxt.style.fontWeight = selectedFont.weigth;
    });
    textSizeInput.addEventListener('input', (evt) => {
        if (!(customTxt instanceof Node)) return;
        customTxt.style.fontSize = evt.target.value + 'px';
    })
    colorSelector(colorSelectorEl, colorEl => {
        const color = colorEl.style.background;
        currentColor = color;
        if (!(customTxt instanceof Node)) return;
        customTxt.style.color = color;
        customTxt.style.borderColor = color;
    }, true)

    //Custom image
    dropZone.addEventListener('dragover', evt => {
        //Prevent navigator doing shit
        evt.preventDefault();
    })
    dropZone.addEventListener('drop', evt => {
        console.log('File(s) dropped');

        // Prevent default behavior (Prevent file from being opened)
        evt.preventDefault();

        if (!(customImg instanceof Node) || evt.dataTransfer.files.length > 1) return;
        const file = evt.dataTransfer.files[0];
        if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(file.type)) return;
        imageField.files = evt.dataTransfer.files;
        updateImage(file);
    })
    imageField.addEventListener('change', evt => {
        if (!(customImg instanceof Node) || evt.target.files.length > 1) return;
        const file = evt.target.files[0];
        if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(file.type)) return;
        updateImage(file);
    })
    ratioCheckbox.addEventListener('change', evt => {
        setKeepRatio(ratioCheckbox.checked)
    })
    new MutationObserver(evt => {
        setKeepRatio(evt[0].attributeName === 'checked' && evt[0].target.checked)
    }).observe(ratioCheckbox, {attributes: true})

    function setKeepRatio(keepRatio) {
        if (!(customImg instanceof Node)) return;
        customImg.style.objectFit = keepRatio ? 'contain' : 'fill';
    }
    async function updateImage(file) {
        const url = await fileToDataUrl(file);

        customImg.src = url;
        const dimensions = await getImageDimension(url);
        const ratio = dimensions.width / dimensions.height;
        let newDimensions = {height: 0, width: 0};
        if (ratio >= 1) {
            //Paysage
            newDimensions.width = Math.min(dimensions.width, limits.width);
            newDimensions.height = newDimensions.width / ratio;
            if (newDimensions.height > limits.height) {
                newDimensions.height = limits.height;
                newDimensions.width = limits.height / ratio
            }
        } else {
            //Portrait
            newDimensions.height = Math.min(dimensions.height, limits.height);
            newDimensions.width = newDimensions.height * ratio;
            if (newDimensions.width > limits.width) {
                newDimensions.width = limits.width;
                newDimensions.height = limits.width / ratio
            }
        }

        const [x, y] = [0, 0];
        newDimensions.top = img.offsetTop + img.height / 2 - (newDimensions.height / 2);
        newDimensions.left = img.offsetLeft + img.width / 2 - (newDimensions.width / 2);
        Object.assign(customImg.style, {
            width: `${newDimensions.width}px`,
            height: `${newDimensions.height}px`,
            top: `${newDimensions.top}px`,
            left: `${newDimensions.left}px`,
            transform: `translate(${x}px, ${y}px)`
        })
        Object.assign(customImg.dataset, { x, y })
    }

    //Other
    let interactible;
    const topMode = imgSource.mode === 'top';
    const img = imageContainer.querySelector('img');
    img.src = topMode ? imgSource.imageTop : imgSource.imageSide;
    img.addEventListener('load', () => {
        const y = Math.round(img.offsetTop + img.height * (topMode ? .27 : .15));
        const x = Math.round(img.offsetLeft + img.width * (topMode ? .17 : .2));
        const width = Math.round(img.width * (topMode ? .66 : .6));
        const height = Math.round(img.height * (topMode ? .46 : .7));

        resizeRestrict = {
            y: y + 5,
            x: x + 5,
            width: width - 5,
            height: height - 5
        };
        dragRestrict = {
            y: y,
            x: x,
            width: width,
            height: height
        };
        limits = {
            width: width,
            height: height,
        };

        let knownPositionEl = form.querySelector('#box-definition');
        let hasKnownPosition = (knownPositionEl instanceof Node);
        let knownPosition = (knownPositionEl || {dataset: null}).dataset;

        tabs(document.querySelector("#mode-selector"), async newMode => {
            imageContainer.querySelectorAll('.customElement').forEach(el => el.remove());
            if (newMode === 'text') {
                if (customImg instanceof Node) customImg.remove();
                customImg = null;

                customTxt = document.createElement('span')
                customTxt.classList.add('customElement');
                customTxt.innerText = textInput.value;
                customTxt.style.color = currentColor;
                customTxt.style.position = 'absolute';
                customTxt.style.top = (hasKnownPosition ? parseFloat(knownPosition.y) : (img.offsetTop + img.height / 2 - 25)) + 'px';
                customTxt.style.left = (hasKnownPosition ? parseFloat(knownPosition.x) : (img.offsetLeft + img.width / 2 - 100)) + 'px';
                customTxt.style.height = (hasKnownPosition ? knownPosition.height : '80') + 'px'
                customTxt.style.width = (hasKnownPosition ? knownPosition.width : '200') + 'px'
                customTxt.style.padding = '6px 8px';
                customTxt.style.display = 'flex';
                customTxt.style.justifyContent = 'center';
                customTxt.style.alignItems = 'center';
                customTxt.style.border = 'dotted 3px';
                customTxt.style.borderColor = currentColor;
                customTxt.style.textAlign = 'center';
                customTxt.style.display = 'block';
                customTxt.style.overflow = 'hidden';
                customTxt.style.fontSize = textSizeInput.value + 'px';
                imageContainer.append(customTxt);

                const selectedFont = fontSelector.options[fontSelector.options.selectedIndex].dataset;
                loadFont(`${selectedFont.font}:${selectedFont.weight}`);
                customTxt.style.fontFamily = `${selectedFont.font}, ${selectedFont.alternative}`
                customTxt.style.fontWeight = selectedFont.weigth;

                interactible = interact('.customElement')
                    .resizable({
                        edges: {top: true, left: true, bottom: true, right: true},
                        listeners: {
                            move: function (event) {
                                let { x, y } = event.target.dataset

                                x = (parseFloat(x) || 0) + event.deltaRect.left
                                y = (parseFloat(y) || 0) + event.deltaRect.top

                                Object.assign(event.target.style, {
                                    width: `${event.rect.width}px`,
                                    height: `${event.rect.height}px`,
                                    transform: `translate(${x}px, ${y}px)`
                                })

                                Object.assign(event.target.dataset, { x, y })
                            }
                        },
                        modifiers: [
                            interact.modifiers.restrictSize({
                                min: { width: 100, height: 50 },
                                max: limits
                            }),
                            interact.modifiers.restrict({
                                restriction: resizeRestrict
                            }),
                        ],
                    })
                    .draggable({
                        listeners: {
                            move(event) {
                                const target = event.target
                                let x = (parseFloat(target.getAttribute('data-x')) || 0);
                                let y = (parseFloat(target.getAttribute('data-y')) || 0);

                                x += event.dx;
                                y += event.dy;

                                target.style.transform =
                                    'translate(' + x + 'px, ' + y + 'px)'

                                target.setAttribute('data-x', x)
                                target.setAttribute('data-y', y)
                            }
                        },
                        modifiers: [
                            interact.modifiers.restrictRect({
                                restriction: dragRestrict
                            }),
                        ]
                    })
            }
            else if (newMode === 'image') {
                if (customTxt instanceof Node) customTxt.remove();
                customTxt = null;

                customImg = document.createElement('img')
                customImg.classList.add('customElement');
                customImg.style.position = 'absolute';
                customImg.style.top = (hasKnownPosition ? parseFloat(knownPosition.y) : (img.offsetTop + img.height / 2 - 25)) + 'px';
                customImg.style.left = (hasKnownPosition ? parseFloat(knownPosition.x) : (img.offsetLeft + img.width / 2 - 100)) + 'px';
                customImg.style.height = (hasKnownPosition ? knownPosition.height : '80') + 'px'
                customImg.style.width = (hasKnownPosition ? knownPosition.width : '200') + 'px'
                customImg.style.padding = '6px 8px';
                customImg.style.display = 'flex';
                customImg.style.justifyContent = 'center';
                customImg.style.alignItems = 'center';
                customImg.style.border = 'dotted 3px #ccc';
                customImg.style.textAlign = 'center';
                customImg.style.display = 'block';
                customImg.style.overflow = 'hidden';
                customImg.style.fontSize = textSizeInput.value + 'px';
                imageContainer.append(customImg);

                let knownImageEl = form.querySelector('#image-definition');
                let hasKnowImage = (knownImageEl instanceof Node);
                if (hasKnowImage) {
                    const body = await fetch(knownImageEl.dataset.url);
                    const blob = await body.blob();
                    updateImage(blob)

                    const files = new DataTransfer();
                    files.items.add(new File(
                        [blob],
                        'image.' + knownImageEl.dataset.type,
                        {type: {'png': 'image/png', 'jpg': 'image/jpeg'}[knownImageEl.dataset.type]}
                    ));
                    imageField.files = files.files;
                }

                interactible = interact('.customElement')
                    .resizable({
                        edges: {top: true, left: true, bottom: true, right: true},
                        listeners: {
                            move: function (event) {
                                let { x, y } = event.target.dataset

                                x = (parseFloat(x) || 0) + event.deltaRect.left
                                y = (parseFloat(y) || 0) + event.deltaRect.top

                                Object.assign(event.target.style, {
                                    width: `${event.rect.width}px`,
                                    height: `${event.rect.height}px`,
                                    transform: `translate(${x}px, ${y}px)`
                                })

                                Object.assign(event.target.dataset, { x, y })
                            }
                        },
                        modifiers: [
                            interact.modifiers.restrictSize({
                                min: { width: 100, height: 50 },
                                max: limits
                            }),
                            interact.modifiers.restrict({
                                restriction: resizeRestrict
                            }),
                        ],
                    })
                    .draggable({
                        listeners: {
                            move(event) {
                                const target = event.target
                                let x = (parseFloat(target.getAttribute('data-x')) || 0);
                                let y = (parseFloat(target.getAttribute('data-y')) || 0);

                                x += event.dx;
                                y += event.dy;

                                target.style.transform =
                                    'translate(' + x + 'px, ' + y + 'px)'

                                target.setAttribute('data-x', x)
                                target.setAttribute('data-y', y)
                            }
                        },
                        modifiers: [
                            interact.modifiers.restrictRect({
                                restriction: dragRestrict
                            }),
                        ]
                    })
            }
            hasKnownPosition = false;
        });


        async function resizeDrag() {
            await interactible.reflow({ name: 'drag', axis: 'x' })

            await interactible.reflow({
                name: 'resize',
                edges: { left: true, bottom: true },
            })
        }
        window.addEventListener('resize', resizeDrag);
    });

    //Form submit
    form.addEventListener('submit', evt => evt.preventDefault())
    form.querySelectorAll('[type="submit"]').forEach(el => el.addEventListener('click', evt => {
        evt.preventDefault();
        let button;
        for (el of evt.composedPath()) {
            if (el.tagName === 'BUTTON') {
                button = el;
                break;
            }
        }

        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.right = '0';
        overlay.style.bottom = '0';
        overlay.style.zIndex = '10000';
        overlay.style.background = 'rgba(0, 0, 0, .7)';

        overlay.style.display = 'flex';
        overlay.style.alignItems = 'center';
        overlay.style.justifyContent = 'center';
        overlay.style.fontSize = '48px';
        overlay.style.color = 'white';
        overlay.innerText = 'Chargement...'

        document.body.append(overlay);

        let _tmp;
        if (customImg instanceof Node) {
            _tmp = customImg.style.borderColor;
            customImg.style.borderColor = 'transparent';
        } else if (customTxt instanceof Node) {
            _tmp = customTxt.style.borderColor;
            customTxt.style.borderColor = 'transparent';
        }

        html2canvas(imageContainer, {
            allowTaint: true,
        }).then(canvas => {
            const data = new FormData(form);
            canvas.toBlob(blob => {
                data.append('screenshot', blob);

                console.log(button)

                if (button.classList.contains('send-form')) button.name = 'continue';
                if (button.classList.contains('go-back')) button.name = 'back';
                data.append(button.name, button.value);

                let node;
                if (customImg instanceof Node) {
                    node = customImg;
                } else if (customTxt instanceof Node) {
                    node = customTxt;
                }

                data.append('box-x', (parseFloat(node.dataset.x || '0') + node.offsetLeft).toString())
                data.append('box-y', (parseFloat(node.dataset.y || '0') + node.offsetTop).toString())
                data.append('box-height', node.offsetHeight)
                data.append('box-width', node.offsetWidth)

                fetch(document.location, {
                    method: 'POST',
                    body: data,
                })
                    .then(body => body.json())
                    .then(json => {
                        if (json.success === true) {
                            window.location.reload();
                        } else if (json.redirect !== undefined) {
                            window.location.replace(json.redirect);
                        }
                        overlay.remove();
                    })
            })

            if (customImg instanceof Node) {
                customImg.style.borderColor = _tmp;
            } else if (customTxt instanceof Node) {
                customTxt.style.borderColor = _tmp;
            }
        }).catch(err => {
            window.Notifications.create({

            })
        })
    }));
}

function getImageDimension(blob) {
    return new Promise((success, reject) => {
        const img = window.document.createElement('img');
        img.addEventListener('load', evt => {
            success({
                height: evt.target.height,
                width: evt.target.width
            })
        })
        img.addEventListener('error', reject);
        img.src = blob;
    })
}

function fileToDataUrl(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
