import 'alpinejs';
import './Notifications';
import {barelConfigurator} from './barel-configurator';
import {priceCalculator} from "./price-calculator";
import {contentConfigurator} from "./content-configurator";

window.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('#page-1') instanceof Node) {
        barelConfigurator(document.querySelector('#page-1'));
        priceCalculator(
            document.querySelector("#total-price"),
            [
                document.querySelector('#dented-option'),
                document.querySelector('#barrel-size-input'),
            ]
        )
    } else if (document.querySelector('#page-2') instanceof Node) {
        const imgSource = document.querySelector('#page-2').dataset;
        contentConfigurator(imgSource, {
            imageContainer: document.querySelector("#image-zone"),
            text: {
                input: document.querySelector("#text"),
                font: document.querySelector("#font-family"),
                size: document.querySelector("#font-size"),
                color: document.querySelector("#color-selector"),
            },
            image: {
                input: document.querySelector("#image-input"),
                zone: document.querySelector("#drop-zone"),
                ratio: document.querySelector('#keep-ratio input[type="checkbox"]')
            },
            form: document.querySelector("#page-2")
        });
    }
});
