export function tabs(node, modeChangeCallable) {
    if (!node instanceof Node) return;

    const input = node.querySelector('input[name="mode"]');

    const options = node.querySelectorAll('.option');
    options.forEach(opt => {
        if (opt.classList.contains('default')) updateView(opt);
        opt.addEventListener('click', evt => {
            evt.preventDefault();
            updateView(opt);
        });
    })

    function updateView(option) {
        options.forEach(opt => {
            document.querySelector(opt.dataset.target).classList.add('hidden')
            opt.classList.remove('text-primary');
        })
        if (node.mode !== option.dataset.mode) modeChangeCallable(option.dataset.mode);
        node.mode = option.dataset.mode;
        input.value = node.mode;
        option.classList.add('text-primary');
        document.querySelector(option.dataset.target).classList.remove('hidden')
    }
}
