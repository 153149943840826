export function colorSelector(node, colorChangeCallable, preferEl = false) {
    if (!(node instanceof Node)) return;

    let input = node.querySelector('input[type="hidden"]');
    let selectedEl;

    node.querySelectorAll(".option").forEach((el) => {
        el.addEventListener('click', evt => {
            evt.stopPropagation();
            if (!evt.target.classList.contains('option')) return;
            selectedEl = evt.target;
            update(evt.target,)
        })

        if (el.classList.contains('default')) {
            selectedEl = el;
            update(el);
        }
    });

    function update(colorEl)
    {
        input.setAttribute('value', colorEl.dataset.value)
        node.querySelectorAll('.icon').forEach((_) => {
            _.classList.add("hidden")
        })
        colorEl.querySelector('.icon').classList.remove('hidden');
        colorChangeCallable(preferEl ? colorEl : colorEl.dataset.value);
    }

}
