window.Notifications = {
    _parent: null,
    _getParent: function () {
        if (this._parent === null) {
            window.Notifications._parent = document.getElementById('notifications-wrapper');
        }
        return this._parent;
    },

    create: function (options) {
        const title = options.title || null;
        if (title === null) {
            throw 'Title is required';
        }

        const dismissible = (options.dismissible !== undefined && options.dismissible !== null) ?
            Boolean(options.dismissible) :
            true;

        const displayTime = parseInt(options.duration || 3000);

        const primaryAction =
            typeof options.primaryAction !== 'object'
            || !options.primaryAction.hasOwnProperty('text')
            || !options.primaryAction.hasOwnProperty('onClick') ?
            null : options.primaryAction;

        const secondaryAction =
            typeof options.secondaryAction !== 'object'
            || !options.secondaryAction.hasOwnProperty('text')
            || !options.secondaryAction.hasOwnProperty('onClick') ?
            null : options.secondaryAction;


        const description = (typeof options.description === 'string') ? options.description : null;


        const icon =
            typeof options.icon !== 'object'
            || !options.icon.hasOwnProperty('path') ?
                null : options.icon;

        const el = (() => {
            const closeHtml = dismissible ? `
                <div class="ml-4 flex-shrink-0 flex">
                    <button class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 close-button">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                        </svg>
                    </button>
                </div>
            `: '';

            const _ = document.createElement('div');
            if (description === null && secondaryAction === null && icon === null && primaryAction.isButton !== true) {
                const titleLinkHtml = primaryAction !== null ? `
                    <button class="primary-action ml-3 flex-shrink-0 text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        ${primaryAction.text}
                    </button>
                `: '';

                _.innerHTML = `
                    <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
                        <div class="rounded-lg shadow-xs overflow-hidden">
                            <div class="p-4">
                                <div class="flex items-center">
                                    <div class="w-0 flex-1 flex justify-between">
                                        <p class="w-0 flex-1 text-sm leading-5 font-medium text-gray-900">
                                            ${title}
                                        </p>
                                        ${titleLinkHtml}
                                    </div>
                                    ${closeHtml}
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            } else {
                const descriptionHtml = description !== null ? `
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        ${description}
                    </p>
                `: '';

                const iconHtml = (() => {
                    if (icon === null) {
                        return '';
                    }

                    let paths;
                    if (typeof icon.path === 'string') {
                        paths = `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="${icon.path}"/>`;
                    } else if (Array.isArray(icon.path)) {
                        paths = '';
                        for (path of icon.path) {
                            paths += `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="${path}"/>`;
                        }
                    } else {
                        throw 'Invalid icon.path value, expected string or array'
                    }

                    return `
                        <div class="flex-shrink-0">
                            <svg class="h-6 w-6 ${icon.color||'text-gray-400'}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                ${paths}
                            </svg>
                        </div>
                    `
                })();

                const actionsHtml = (() => {
                    if (primaryAction === null && secondaryAction === null) {
                        return '';
                    }

                    const primaryActionHtml = (() => {
                        if (primaryAction === null) {
                            return '';
                        }

                        if (primaryAction.isButton) {
                            return `
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button type="button" class="primary-action inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                        ${primaryAction.text}
                                    </button>
                                </span>
                            `;
                        } else {
                            return `
                                <button class="primary-action text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    ${primaryAction.text}
                                </button>
                            `;
                        }
                    })();

                    const secondaryActionHtml = (() => {
                        if (secondaryAction === null) {
                            return '';
                        }

                        if (secondaryAction.isButton) {
                            return `
                                <span class="${primaryAction !== null ? 'ml-3' : ''} inline-flex rounded-md shadow-sm">
                                    <button type="button" class="secondary-action inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                        ${secondaryAction.text}
                                    </button>
                                </span>
                            `;
                        } else {
                            return `
                                <button class="${primaryAction !== null ? 'ml-6' : ''} secondary-action text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    Dismiss
                                </button>
                            `;
                        }
                    })();

                    return `
                        <div class="${primaryAction.isButton === true || secondaryAction.isButton === true ? 'mt-4' : 'mt-2'}">
                            ${primaryActionHtml}
                            ${secondaryActionHtml}
                        </div>
                    `;
                })();

                _.innerHTML = `
                    <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
                        <div class="rounded-lg shadow-xs overflow-hidden">
                            <div class="p-4">
                                <div class="flex items-start">
                                    ${iconHtml}
                                    <div class="${icon !== null ? 'ml-3' : ''} w-0 flex-1 pt-0.5">
                                        <p class="text-sm leading-5 font-medium text-gray-900">
                                            ${title}
                                        </p>
                                        ${descriptionHtml}
                                        ${actionsHtml}
                                    </div>
                                    ${closeHtml}
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            }

            return  _.querySelector(':first-child');
        })();

        if (dismissible) {
            const closeBtn = el.querySelector('.close-button');
            closeBtn.addEventListener('click', () => {this.dismiss(el)});
        }

        if (primaryAction !== null) {
            const titleActionEl = el.querySelector('.primary-action');
            titleActionEl.addEventListener('click', primaryAction.onClick)
        }
        if (secondaryAction !== null) {
            const titleActionEl = el.querySelector('.secondary-action');
            titleActionEl.addEventListener('click', secondaryAction.onClick)
        }

        window.setTimeout(() => {this.dismiss(el)}, displayTime);

        el.id = Math.random().toString(36).replace('0.', '_');
        this._getParent().appendChild(el);
        this.show(el);


        return el.id;
    },

    dismiss: function (el) {
        this.hide(el, () => {
            el.remove();
        })
    },

    get: function (id) {
        return this._getParent().getElementById(id);
    },

    show: function (el, then = () => null) {
        el.classList.add('transform', 'ease-out', 'duration-300', 'transition')
        el.classList.add('translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2')
        window.setTimeout(() => {
            el.classList.remove('translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2')
            el.classList.add('translate-y-0', 'opacity-100', 'sm:translate-x-0')

            window.setTimeout(() => {
                el.classList.remove('transform', 'ease-out', 'duration-300', 'transition');
                then();
            }, 450)
        }, 50)
    },

    hide: function (el, then = () => null) {
        el.classList.add('transform', 'ease-in', 'duration-100')
        el.classList.add('opacity-100')
        window.setTimeout(() => {
            el.classList.remove('opacity-100')
            el.classList.add('opacity-0')

            window.setTimeout(() => {
                el.classList.remove('transform', 'ease-in', 'duration-100');
                then()
            }, 250)
        }, 50)
    },
}